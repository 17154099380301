import dayjs from 'dayjs';
import { Parser } from 'expr-eval';

import { getAge } from '@breathelife/date-helpers';
import { AgeRoundingType, InstancesCountMethod, NodeIdToCount } from '@breathelife/types';

import { formatFormulaVariableName } from './queryOperators';

const parser = new Parser();

// Custom functions
parser.functions.dateToAge = function dateToAge(
  date: string | 0,
  roundingType: AgeRoundingType,
  defaultReturnValue: string | number = NaN
): number | string {
  if (date === 0) {
    // Invalid dates are sometimes given the value 0.
    return defaultReturnValue;
  }
  if (typeof date !== 'string') {
    throw new Error('dateToAge: date parameter must be a string');
  }

  const dateOfBirth = dayjs(date);
  if (!dateOfBirth.isValid()) {
    return defaultReturnValue;
  }

  const age = getAge(dateOfBirth.toDate(), roundingType);
  return age;
};

parser.functions.lookupWithDefault = function lookupWithDefault(
  lookup: {
    [key: string]: any;
    [index: number]: any;
  },
  index: string | number,
  defaultValue: any
): any {
  const value: any = lookup[index];
  if (typeof value !== 'undefined') {
    return value;
  }

  return defaultValue;
};

function getTotalCountBasedOnCountMethod(variables: Record<string, unknown>, nodeIdsInfo: NodeIdToCount[]): number {
  let count = 0;
  nodeIdsInfo.forEach((nodeId) => {
    Object.keys(variables).forEach((key) => {
      const comparisonKey = key.includes('___') ? key.split('___')[1] : key;
      if (comparisonKey === formatFormulaVariableName(nodeId.nodeId)) {
        const value = variables[key];
        if (!value) {
          return;
        }
        if (Array.isArray(value) && nodeId.countMethod === InstancesCountMethod.addLengthOfArray) {
          count += value.length;
        } else if (nodeId.countMethod === InstancesCountMethod.addOneIfExists) {
          count += 1;
        } else if (typeof value === 'number' && nodeId.countMethod === InstancesCountMethod.addValue) {
          count += value;
        } else if (nodeId.countMethod === InstancesCountMethod.addOneIfValueEquals && value === nodeId.compareValue) {
          count += 1;
        } else if (
          nodeId.countMethod === InstancesCountMethod.addNumberIfValueEquals &&
          value === nodeId.compareValue
        ) {
          count += nodeId.numberToAdd || 0;
        }
      }
    });
  });

  return count;
}

parser.functions.getRepeatedCount = function getRepeatedCount(
  variables: Record<string, unknown>,
  nodeIdsToAdd: NodeIdToCount[],
  nodeIdsToSubtract: NodeIdToCount[]
) {
  const totalToAdd = getTotalCountBasedOnCountMethod(variables, nodeIdsToAdd);
  const totalToSubtract = getTotalCountBasedOnCountMethod(variables, nodeIdsToSubtract);
  return totalToAdd - totalToSubtract;
};

parser.functions.consoleLog = function consoleLog(value: any, message?: string): any {
  if (message) console.log(message);
  console.log(JSON.stringify(value));
  return value;
};

parser.functions.keepOldestDate = function keepOldestDate(variables: Record<string, string>) {
  const sortedDateOldestFirst = Object.values(variables)
    .filter(Boolean)
    .sort((a, b) => {
      const isBefore = dayjs(a).isBefore(dayjs(b));
      return isBefore ? -1 : 1;
    });

  return sortedDateOldestFirst[0];
};

type RangesMap = { min?: number; max?: number; value: any }[];

parser.functions.getValueIfInputInRange = function getValueIfInputInRange(input: number, rangesMap: RangesMap): any {
  let returnValue;
  rangesMap.some(({ value, min, max }) => {
    if (((!min && min !== 0) || input >= min) && ((!max && max !== 0) || input <= max)) {
      returnValue = value;
      return true;
    }
  });

  return returnValue;
};

export default parser;
