import {
  AgeRoundingType,
  BooleanOperator,
  CollectionOperator,
  ComparisonOperator,
  Condition,
  ConditionCollection,
  ConditionSingleField,
  DateTimeReference,
  DateUnit,
  QueryOperator,
  ShiftMagnitude,
  TimeRoundingType,
  TimeUnitRoundingType,
} from '@breathelife/types';

/** Helper to create a condition based on the min age rather than the max date of birth */
export function minimumAgeCondition(
  birthDateNodeId: string,
  value: number,
  unit: DateUnit,
  roundingType: AgeRoundingType | TimeUnitRoundingType
): ConditionSingleField {
  return {
    nodeId: birthDateNodeId,
    operator: ComparisonOperator.lessThanOrEqual,
    controlValueQuery: {
      select: [DateTimeReference.currentDateTime],
      operator: QueryOperator.shiftDateBackwards,
      operatorParams: {
        dateUnit: unit,
        shiftDateParams: {
          shiftMagnitude: ShiftMagnitude.latestDateRespectingShiftValue,
          dateFormat: 'YYYY-MM-DD',
          shiftValue: value,
          timeRoundingType: roundingType,
        },
      },
    },
  };
}

/** Helper to create a condition based on the max age rather than the min date of birth */
export function maximumAgeCondition(
  birthDateNodeId: string,
  value: number,
  unit: DateUnit,
  roundingType: TimeRoundingType
): ConditionSingleField {
  return {
    nodeId: birthDateNodeId,
    operator: ComparisonOperator.greaterThanOrEqual,
    controlValueQuery: {
      select: [DateTimeReference.currentDateTime],
      operator: QueryOperator.shiftDateBackwards,
      operatorParams: {
        dateUnit: unit,
        shiftDateParams: {
          shiftMagnitude: ShiftMagnitude.earliestDateRespectingShiftValue,
          dateFormat: 'YYYY-MM-DD',
          shiftValue: value,
          timeRoundingType: roundingType,
        },
      },
    },
  };
}

/** Helper to create a condition based on the max age for items in a collection */
export function maximumAgesCondition({
  birthDateNodeId,
  collectionNodeId,
  value,
  unit,
  roundingType = AgeRoundingType.closestBirthday,
  collectionOperator = CollectionOperator.every,
  bypassAgeConditionIf,
}: {
  birthDateNodeId: string;
  collectionNodeId: string;
  value: number;
  unit?: DateUnit;
  roundingType?: TimeRoundingType;
  collectionOperator?: CollectionOperator;
  bypassAgeConditionIf?: Condition;
}): Condition {
  return agesCondition({
    birthDateComparisonValue: value,
    birthDateNodeId: birthDateNodeId,
    comparisonOperator: ComparisonOperator.greaterThanOrEqual,
    shiftMagnitude: ShiftMagnitude.earliestDateRespectingShiftValue,
    unit,
    roundingType,
    collectionNodeId,
    collectionOperator,
    bypassAgeConditionIf,
  });
}

/** Helper to create a condition based on the min age for items in a collection */
export function minimumAgesCondition({
  birthDateNodeId,
  collectionNodeId,
  value,
  unit,
  roundingType = AgeRoundingType.closestBirthday,
  collectionOperator = CollectionOperator.every,
  bypassAgeConditionIf,
}: {
  birthDateNodeId: string;
  collectionNodeId: string;
  value: number;
  unit?: DateUnit;
  roundingType?: TimeRoundingType;
  collectionOperator?: CollectionOperator;
  bypassAgeConditionIf?: Condition;
}): Condition {
  return agesCondition({
    birthDateComparisonValue: value,
    birthDateNodeId: birthDateNodeId,
    comparisonOperator: ComparisonOperator.lessThanOrEqual,
    shiftMagnitude: ShiftMagnitude.latestDateRespectingShiftValue,
    unit,
    roundingType,
    collectionNodeId,
    collectionOperator,
    bypassAgeConditionIf,
  });
}

export function lastIncidentDateCondition(
  dateNodeId: string,
  value: number,
  unit: DateUnit,
  comparisonOperator: ComparisonOperator,
  timeRoundingType: TimeRoundingType = AgeRoundingType.lastBirthday
): Condition {
  return {
    query: {
      select: [DateTimeReference.currentDateTime],
      operator: QueryOperator.shiftDateBackwards,
      operatorParams: {
        dateUnit: unit,
        shiftDateParams: {
          shiftMagnitude: ShiftMagnitude.latestDateRespectingShiftValue,
          dateFormat: 'YYYY-MM-DD',
          shiftValue: value,
          timeRoundingType,
        },
      },
    },
    operator: comparisonOperator,
    controlValue: dateNodeId,
  };
}

function agesCondition({
  birthDateComparisonValue,
  birthDateNodeId,
  collectionNodeId,
  comparisonOperator,
  collectionOperator,
  bypassAgeConditionIf,
  shiftMagnitude,
  unit = DateUnit.year,
  roundingType,
}: {
  birthDateComparisonValue: string | number;
  birthDateNodeId: string;
  collectionNodeId: string;
  comparisonOperator: ComparisonOperator;
  collectionOperator: CollectionOperator;
  bypassAgeConditionIf?: Condition;
  shiftMagnitude: ShiftMagnitude;
  unit?: DateUnit;
  roundingType: TimeRoundingType;
}): ConditionCollection {
  const birthDateCondition = {
    nodeId: birthDateNodeId,
    operator: comparisonOperator,
    controlValueQuery: {
      select: [DateTimeReference.currentDateTime],
      operator: QueryOperator.shiftDateBackwards,
      operatorParams: {
        dateUnit: unit,
        shiftDateParams: {
          shiftMagnitude,
          dateFormat: 'YYYY-MM-DD',
          shiftValue: Number(birthDateComparisonValue),
          timeRoundingType: roundingType,
        },
      },
    },
  };
  const innerCondition = bypassAgeConditionIf
    ? {
        operator: BooleanOperator.or,
        conditions: [bypassAgeConditionIf, birthDateCondition],
      }
    : birthDateCondition;

  return {
    collectionOperator: collectionOperator,
    collection: collectionNodeId,
    conditions: [innerCondition],
    operator: undefined,
  };
}
